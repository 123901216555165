import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import MarketLayout from 'web/components/market_layout';
import MinimalLayout from 'web/components/minimal_layout';
import StepsHeader from 'web/basket/components/steps_header';

import {reducer, actions} from './duck';
import SignIn from './sign_in';

const segmentFeature = 'sign-in-page';
// eslint-disable-next-line react/prefer-stateless-function
class SignInPage extends Component {
  render() {
    const Layout = this.props.useMinimalLayout ? MinimalLayout : MarketLayout;
    const helmet = (
      <Helmet>
        <title>Sign In | Good Eggs</title>
        {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
        {this.props.useMinimalLayout && <html className="with-fixed-minimal-footer" />}
        <meta
          name="google-signin-client_id"
          content={this.props.settings.googleAuthenticateClientId}
        />
      </Helmet>
    );
    if (this.props.user) {
      return (
        <Layout>
          {helmet}

          <div className="sign-in-page narrow-page">
            <div className="sign-in-page__sign-out-first">
              <p className="sign-in-page__sign-out-first-copy">
                You{"'"}re already signed in as {this.props.user.firstName} ({this.props.user.email}
                ).
              </p>
              <p className="sign-in-page__sign-out-first-copy">
                Please
                <a className="sign-in-page__sign-out-first-link" href={this.props.signOutUrl}>
                  {' '}
                  sign out{' '}
                </a>
                if you{"'"}d like to use a different account.
              </p>
            </div>
          </div>
        </Layout>
      );
    }

    return (
      <Layout>
        {helmet}
        {this.props.useMinimalLayout ? <StepsHeader currentStep={1} /> : null}
        <SignIn
          segmentFeature={segmentFeature}
          alert={this.props.alert}
          error={this.props.error}
          submitSignInForm={this.props.submitSignInForm}
          signUpUrl={this.props.signUpUrl}
          resetPasswordUrl={this.props.resetPasswordUrl}
        />
      </Layout>
    );
  }
}

SignInPage.propTypes = {
  submitSignInForm: PropTypes.func,
  working: PropTypes.bool,
  error: PropTypes.object,
  signUpUrl: PropTypes.string,
  resetPasswordUrl: PropTypes.string,
  destinationUrl: PropTypes.string,
  signOutUrl: PropTypes.string,
  useMinimalLayout: PropTypes.bool,
  user: PropTypes.object,
  alert: PropTypes.string,
  loginWithGoogle: PropTypes.func,
  settings: PropTypes.shape({
    googleAuthenticateClientId: PropTypes.string.isRequired,
  }).isRequired,
};

SignInPage.reducer = reducer;

SignInPage.pageName = 'Sign In';

export function mapStateToProps(state) {
  return {
    working: state.navigation.isNavigating || state.signInForm.isAuthenticating,
    error: state.signInForm.error || state.socialSignIn.error,
    signUpUrl: state.signInPage.signUpUrl,
    destinationUrl: state.signInPage.destinationUrl,
    useMinimalLayout: state.signInPage.useMinimalLayout,
    resetPasswordUrl: state.signInPage.resetPasswordUrl,
    signOutUrl: state.signInPage.signOutUrl,
    user: state.user,
    alert: state.signInPage.alert,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, actions)(SignInPage);
